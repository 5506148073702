import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { func, object } from 'prop-types'

// modules
import * as actions from '@modules/redux/actions'
import Auth from '@modules/auth'
import Url from '@modules/url'
import Cookie from '@modules/cookie'
import { IMPERSONATION_COOKIE_NAME } from '@modules/constants'

const Logout = ({ logout, location, ...rest }) => {
  const queryParams = Url.parseSearch(location.search)

  useEffect(() => {
    Cookie.remove([IMPERSONATION_COOKIE_NAME], {}, () => null)
    logout(queryParams.forwardUrl || Auth.forwardUrl)
  })
  return <div>Redirecting...</div>
}

Logout.propTypes = {
  logout: func,
  location: object
}

Logout.defaultProps = {
  logout: () => {},
  location: {}
}

const mapStateToProps = createStructuredSelector({})

export default connect(mapStateToProps, actions)(Logout)
